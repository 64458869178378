<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>Cenaze Fonu</li>
            </ul>
            <h2>Cenaze Fonu</h2>
        </div>
    </div>
</div>



<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="container application">
            <div class="tab-wrap application">

                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>
                <div class="tab__content">
                    <h3 application>Kişisel Bilgiler</h3>
                    <form [formGroup]="mainForm">
                        <fieldset class="row col md-12">
                            <div class="col-md-6">
                                <label class="form-label">Adı</label>
                                <input type="text" class="form-control" formControlName="firstName" placeholder="Adı"
                                    (keydown)="checkInputText($event)">
                                <div *ngIf="mainForm.get('firstName')?.invalid && (mainForm.get('firstName').dirty || mainForm.get('firstName').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('firstName').errors?.required">
                                        Bu alan boş bırakılamaz.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <label class="form-label">Soyadı</label>
                                <input type="text" class="form-control" formControlName="lastName" placeholder="Soyadı">
                                <div *ngIf="mainForm.get('lastName')?.invalid && (mainForm.get('lastName').dirty || mainForm.get('lastName').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('lastName').errors?.required">
                                        Bu alan boş bırakılamaz.
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Person No</label>
                                <input type="text" class="form-control" formControlName="identificationNumber"
                                    placeholder="Person No">
                                <div *ngIf="mainForm.get('identificationNumber')?.invalid && (mainForm.get('identificationNumber').dirty || mainForm.get('identificationNumber').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('identificationNumber').errors?.required">
                                        Bu alan boş bırakılamaz.
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Doğum Yeri</label>
                                <input type="text" class="form-control" formControlName="birthPlace"
                                    placeholder="Doğum Yeri">
                                <div *ngIf="mainForm.get('birthPlace')?.invalid && (mainForm.get('birthPlace').dirty || mainForm.get('birthPlace').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('birthPlace').errors?.required">
                                        Bu alan boş bırakılamaz.
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Cinsiyet</label>
                                <select formControlName="genderId" name="genderId" class="form-control">
                                    <option value="0" selected disabled>Seçiniz</option>
                                    <option *ngFor="let item of gender" [value]="item.id">{{item.name}}</option>
                                </select>
                                <div *ngIf="mainForm.get('genderId')?.invalid && (mainForm.get('genderId').dirty || mainForm.get('genderId').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('genderId').errors?.required">
                                        Bu alan boş bırakılamaz.
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Uyruk</label>
                                <select formControlName="nationality" name="nationality" class="form-control">
                                    <option value="0" selected disabled>Seçiniz</option>
                                    <option *ngFor="let item of nationality" [value]="item.name">{{item.name}}</option>
                                </select>
                                <div *ngIf="mainForm.get('nationality')?.invalid && (mainForm.get('nationality').dirty || mainForm.get('nationality').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('nationality').errors?.required">
                                        Bu alan boş bırakılamaz.
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Doğum Tarihi</label>
                                <input type="date" class="form-control" formControlName="birthDay"
                                    placeholder="Doğum Tarihi">
                                <div *ngIf="mainForm.get('birthDay')?.invalid && (mainForm.get('birthDay').dirty || mainForm.get('birthDay').touched)"
                                    class="alert">
                                    <div *ngIf="mainForm.get('birthDay').errors?.required">
                                        Bu alan boş bırakılamaz.
                                    </div>
                                </div>

                            </div>



                            <h3 class="form-title">Adres</h3>
                            <div formGroupName="address">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="form-label">Cadde</label>
                                        <input type="text" class="form-control" formControlName="street"
                                            placeholder="Cadde">
                                        <div *ngIf="mainForm.get('address.street')?.invalid && (mainForm.get('address.street').dirty || mainForm.get('address.street').touched)"
                                            class="alert">
                                            <div *ngIf="mainForm.get('address.street').errors?.required">
                                                Bu alan boş bırakılamaz.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Posta Kodu</label>
                                        <input type="number" class="form-control" formControlName="postCode"
                                            placeholder="Posta Kodu">
                                        <div *ngIf="mainForm.get('address.postCode')?.invalid && (mainForm.get('address.postCode').dirty || mainForm.get('address.postCode').touched)"
                                            class="alert">
                                            <div *ngIf="mainForm.get('address.postCode').errors?.required">
                                                Bu alan boş bırakılamaz.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Mahalle</label>
                                        <input type="text" class="form-control" formControlName="district"
                                            placeholder="Mahalle">
                                        <div *ngIf="mainForm.get('address.district')?.invalid && (mainForm.get('address.district').dirty || mainForm.get('address.district').touched)"
                                            class="alert">
                                            <div *ngIf="mainForm.get('address.district').errors?.required">
                                                Bu alan boş bırakılamaz.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Email</label>
                                        <input type="text" class="form-control" formControlName="email"
                                            placeholder="Email">
                                        <div *ngIf="mainForm.get('address.email')?.invalid && (mainForm.get('address.email').dirty || mainForm.get('address.email').touched)"
                                            class="alert">
                                            <div *ngIf="mainForm.get('address.email').errors?.required">
                                                Bu alan boş bırakılamaz.
                                            </div>
                                            <div *ngIf="mainForm.get('address.email')?.errors?.email" class="alert">
                                                Lütfen geçerli bir email adresi giriniz.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Telefon Numarası</label>
                                        <input type="number" class="form-control" formControlName="phoneNumber"
                                            placeholder="Telefon Numarası">
                                        <div *ngIf="mainForm.get('address.phoneNumber')?.invalid && (mainForm.get('address.phoneNumber').dirty || mainForm.get('address.phoneNumber').touched)"
                                            class="alert">
                                            <div *ngIf="mainForm.get('address.phoneNumber').errors?.required">
                                                Bu alan boş bırakılamaz.
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>

                    <div class="d-flex gap-2 mb-3">
                        <button type="button" class="btn btn-primary" (click)="spouseFormShow()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-plus" viewBox="0 0 16 16">
                                <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Eş Bilgisi Ekle
                        </button>
                    </div>

                    <div [ngStyle]="showSpouseForm==true? {'display':'block'} :  {'display':'none'}">
                   
                             <form  [formGroup]="spouseForm">
                            <h3 class="form-title">Eş Bilgileri</h3>
                            <div formGroupName="user">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="form-label">Adı</label>
                                        <input type="text" class="form-control" formControlName="firstName"
                                            placeholder="Adı">

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Soyadı</label>
                                        <input type="text" class="form-control" formControlName="lastName"
                                            placeholder="Soyadı">

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Person No</label>
                                        <input type="text" class="form-control" formControlName="identificationNumber"
                                            placeholder="Person No">

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Doğum Yeri</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" formControlName="birthPlace"
                                                placeholder="Doğum Yeri">
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Doğum Tarihi</label>
                                        <input type="date" class="form-control" formControlName="birthDay"
                                            placeholder="Doğum Tarihi">

                                    </div>
                                   
                                    <div class="col-md-6">
                                        <label class="form-label">Uyruk</label>
                                        <div class="input-group">
                                            <select formControlName="nationality" name="nationality"
                                                class="form-control">
                                                <option value="0" selected disabled>Seçiniz</option>
                                                <option *ngFor="let item of nationality" [value]="item.name">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Cinsiyet</label>
                                <select formControlName="genderId" name="genderId" class="form-control">
                                    <option value="0" selected disabled>Seçiniz</option>
                                    <option *ngFor="let item of gender" [ngValue]="item.id">{{item.name}}
                                    </option>
                                </select>

                            </div>
                        </form>
                    </div>
                    <div class="d-flex gap-2 mb-3">
                        <button type="button" class="btn btn-primary"
                            (click)="showFamilyMemberForm()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Çocuk Bilgisi Ekle
                        </button>
                    </div>

                    <div  [ngStyle]="showMemberForm==true? {'display':'block'} :  {'display':'none'} ">
                        <form [formGroup]="memberForm">
                            <div class="col-lg-12 col-md-12 ">
                                <h3 class="form-title">Çocuk Bilgileri</h3>
                            </div>
                            <div class="row" formArrayName="familyMembers">
                                <div *ngFor="let item of familyMembers.controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <h3>{{i+1+'.'}}</h3>
                                        <div class="col-md-6">
                                            <label class="form-label">Ad</label>
                                            <input type="text" class="form-control" formControlName="firstName"
                                                placeholder="Adı">

                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">Soyad</label>
                                            <input type="text" class="form-control" formControlName="lastName"
                                                placeholder="Soyadı">

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-label">Person No</label>
                                                <input type="text" class="form-control"
                                                    formControlName="identificationNumber" placeholder="Person No">
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">Doğum Yeri</label>
                                            <input type="text" class="form-control" formControlName="birthPlace"
                                                placeholder="Doğum Yeri">

                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-label">Cinsiyet</label>
                                            <select formControlName="genderId" name="genderId" class="form-control">
                                                <option value="0" selected disabled>Seçiniz</option>
                                                <option *ngFor="let item of gender" [ngValue]="item.id">{{item.name}}
                                                </option>
                                            </select>

                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="form-label">Doğum Tarihi</label>
                                            <input type="date" class="form-control" formControlName="birthDay"
                                                placeholder="Doğum Tarihi">

                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex gap-2 mb-3">
                                <button type="button" class="btn btn-primary"
                                    (click)="addNewFamilyMember()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                    Çocuk Ekle
                                </button>
                            </div>
                    
                        </form>
                       
                    </div>
                    <button [disabled]="isLoading" (click)="saveForm()" class="btn btn-primary">
                        <span *ngIf="!isLoading">Gönder</span>
                        <span *ngIf="isLoading">
                            <i class="fa fa-spinner fa-spin"></i> Gönderiliyor...
                        </span>
                        <div *ngIf="!isLoading" routerLink=""></div>
                    </button>

                </div>

            </div>

        </div>
    </div>
</div>
<app-header-style-two></app-header-style-two>