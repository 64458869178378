<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>Haberler</li>
            </ul>
            <h2>Haberler</h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="courses-title">
                        <h2>{{news?.frontAnnouncementRecords[0]?.title}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="container courses-details-desc">
            <div class="row">
                <!-- <div class="courses-details-image text-center">
                    <img src={{photoUrl+news?.frontAnnouncementRecords[0]?.fileUrl}} alt="image" class="news-image">
                </div> -->
                <div class="col-lg-12 col-md-12" >

                    <a [innerHTML]="news?.frontAnnouncementRecords[0]?.shortDescription"></a>
                    
                    <div class="why-you-learn" > 
                       <a [innerHTML]="news?.frontAnnouncementRecords[0]?.longDescription"></a>

                    </div>
                       <!-- <ul>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Pasaportu (Süresi 31 Ekim 2023 tarihine kadar olması gerekiyor)
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Vesikalık Resim (1 adet Arka Fon Beyaz)
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Menenjit aşısı olduğunu gösteren sarı renkli Uluslararası ‘Aşı Kartı’ (Menveo ACW 135 Y)
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Hac Ücreti Ödendi Dekontu
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
        
                <div class="col-lg-6 col-md-6">
                    <h3>Hac Ücretleri</h3>
                    <ul class="requirements-list">
                        <li>4 Kişilik Odada Kişi Başı : 16.500 Sek</li>
                        <li>3 Kişilik Odada Kişi Başı : 17.000 Sek</li>
                        <li>2 Kişilik Odada Kişi Başı : 17.500 Sek</li>
                    </ul>
                </div> -->
            </div>
        
            <!-- <div class="row">
                <div class="col-lg-6 col-md-6">
                    <h3>6-11 YAŞ ARASI ÇOCUKLAR İÇİN ÜCRET TABLOSU</h3>
                    <ul class="requirements-list">
                        <li>4 Kişilik Odada Kişi Başı : 14.500 Sek</li>
                        <li>3 Kişilik Odada Kişi Başı : 15.000 Sek</li>
                        <li>2 Kişilik Odada Kişi Başı : 15.500 Sek</li>
                        <li>24 Aylık ve 5 Yaş Arası Çocuklar İçin Ücret : 13.500 Sek</li>
                        <li>0-24 Aylık arası çocuklar için ücret : 4.250</li>
                    </ul>
                </div>
        
                <div class="col-lg-6 col-md-6">
                    <h3>BANKA HESAP BİLGİLERİ</h3>
                    <ul>
                        <li>BANKGIRO : 5475-2654</li>
                        <li>HESAP SAHİBİ : TURKISKA AMBASSADEN</li>
                        <li>SWIFT : ESSESESSXXX </li>
                        <li>IBAN: : SE78 5000 0000 0527 7112 4009 </li>
                        <li>BANKA : SEB </li>
                    </ul>
                </div>
                
            </div> -->
        </div>
        
    </div>
    <app-header-style-two></app-header-style-two>
