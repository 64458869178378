import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { services } from './../../../services/services';

@Component({
    selector: 'app-homesix-courses',
    templateUrl: './homesix-courses.component.html',
    styleUrls: ['./homesix-courses.component.scss']
})
export class HomesixCoursesComponent implements OnInit {
    list: any[]=[]
    photoUrl:string=environment.serverUrl
    constructor(private services: services) { }

    ngOnInit(): void {
  
      
        this.services.getFrontAllMosque().subscribe((data: any) => {
            this.list = data;
            
        });
    
    }

    // for tab click event
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }


  

}