import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  path = environment.apiUrl;
  constructor(private http: HttpClient) { }


  getAllAssociations(){
    const url = `Association/GetAllAssociations`;
    return this.http.get(this.path + url);
  }

  getAllAirports(){
    const url = `Airport/GetAllAirports`;
    return this.http.get(this.path + url);
  }

  getAllRoomTypes(): Observable<any> {
    const url = `RoomType/GetAllRoomTypes`;
    return this.http.get(this.path + url);

  }

  addHajjForm(data: any){
    const url=`HacForm/HajjRegistrationForm`;
    return this.http.post(this.path + url,data);
  }

  addUmreForm(data: any){
    const url=`UmreForm/UmrahRegistrationForm`;
    return this.http.post(this.path + url,data);
  }

  addFile(data:any){
    const url=`UmreForm/PostPicture`
    return this.http.post<any>(this.path + url, data);
  }



}
