import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-information-application',
  templateUrl: './information-application.component.html',
  styleUrls: ['./information-application.component.scss']
})
export class InformationApplicationComponent {
  pages: any
  photoUrl: string = environment.serverUrl

  constructor(private activeRoute: ActivatedRoute) { }


  ngOnInit(): void {
    this.activeRoute.data.subscribe(
      (data: { pages: any }) => {
        if (data.pages) {
          this.pages = data.pages;

        }
      });
  }

}
