<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-start">
                <!-- <h2>Cenaze Fonu Hakkında</h2> -->
                <img src="../../../../assets/cf-imgs/slide5.jpg" alt="">
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="values-content">
                <h3>Cenaze Fonunun Amacı</h3>
                <p>İsveç’te yaşayan Türkiye Cumhuriyeti vatandaşları ile İsveç vatandaşı statüsündeki Müslümanların,
                    Şartname ’de yazılı şartları zamanında yerine getirdikleri takdirde, İsveç’te vefat etmeleri halinde
                    cenazelerinin İslami kurallara göre techiz ve tekfininin yapılabilmesi için, resmi işlemlerini
                    tamamlamak,
                    Türkiye veya İsveç’te defnini sağlamak ve bu işlemlerden doğacak masrafları imkânlar ölçüsünde
                    karşılamaktır.
                    Fon, risk payını geçmeyecek şekilde İDV tüzüğündeki esaslar çerçevesinde faaliyet gösteren dernek ve
                    kuruluşlara yardım yapabilir,
                    kendi hizmet ve faaliyet alanını geliştirmek amacıyla yatırım yapabilir. Söz konusu yardım ve
                    yatırım
                    kararlarının alınmasında ve miktarların belirlenmesinde karar mercii Vakıf Yönetim Kurulu’dur. </p>

                <h3>Genel Bilgi</h3>

                <p>Cenaze Nakli Yardımlaşma Fonu, faaliyet ve hizmetlerini İsveç Diyanet Vakfı’nın bünyesi ve
                    sorumluluğu altında sürdürür. Fon, her türlü hizmetini imkânlar ölçüsünde karşılıklı yardımlaşma
                    esasına dayalı yürütür ve bir şirket statüsüne sahip değildir. Fon üyesi olmak Vakıf üyesi olmak
                    anlamını kesinlikle taşımaz.
<br>
                    <b>Detaylı Bilgi ve Üyelik için</b> +46 8 777 91 34 nolu telefondan ulaşabilirsiniz.<br>
                    <b>E-posta adresimiz</b> : svenskaislamstiftelsen@hotmail.com
                </p>

                <h3>Fon Üyeliği Şartları</h3>

                <ul>
                    <li>
                        Müslüman olmak,
                    </li>
                    <li>
                        İsveç’te resmi ikametle T.C. vatandaşı veya çifte uyruklu (Türk ve İsveç vatandaşı) statüsünde
                        olmak,
                    </li>
                    <li> Turist veya ilticacı olmamak,</li>
                    <li>İsveç vatandaşlık numarasına (Personnummer) sahip olmak,</li>
                    <li> Üyelik şartlarına sahip aile reisi, istediği takdirde aynı şartları taşıyan eşini ve
                        kendi üzerine kayıtlı 18 yaşını doldurmamış çocuklarını da Fon’a üye yaptırabilir.</li>
                </ul>
            </div>
        </div>
    </div>
</div>