import { Component, OnInit } from '@angular/core';
import { services } from 'src/app/components/services/services';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    appSettings: any;
    address:any;
    telephone1:any;
    telephone2:any;
    fax:any;
    facebook:any;
    twitter:any;
    instagram:any;
    linkedin:any;
    youtube:any;
    email1: any;
    email2: any;
    constructor(private service: services) { }

    ngOnInit(): void {
        this.getAppSettings()
    }

    submit(form){
        var name = form.name;

        
        var email = form.email;


        var number = form.number;

        
        var subject = form.subject;

        
        var message = form.message;

    }

    getAppSettings() {
        this.service.getAppSetting().subscribe((data: any) => {
          this.appSettings = data;
          this.address = data.find(item => item.key === "adres")?.value;
          this.telephone1 = data.find(item => item.key === "telefon1")?.value;
          this.telephone2 = data.find(item => item.key === "telefon2")?.value;
          this.fax = data.find(item => item.key === "fax")?.value;
          this.email1 = data.find(item => item.key === "email1")?.value;
          this.email2 = data.find(item => item.key === "email2")?.value;
        //   this.facebook = data.find(item => item.key === "facebook")?.value;
        //   this.twitter = data.find(item => item.key === "twitter")?.value;
        //   this.instagram = data.find(item => item.key === "instagram")?.value;
        //   this.linkedin = data.find(item => item.key === "linkedin")?.value;
        //   this.youtube = data.find(item => item.key === "youtube")?.value;


        })
      }

}