import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { services } from './../../services/services';
@Component({
    selector: 'app-coming-soon-page',
    templateUrl: './coming-soon-page.component.html',
    styleUrls: ['./coming-soon-page.component.scss']
})
export class ComingSoonPageComponent {
    //Hajj
    pages: any;
    photoUrl: string = environment.serverUrl;

    constructor(private activeRoute: ActivatedRoute, private services: services) { }

    ngOnInit(): void {
        this.activeRoute.data.subscribe(
            (data: { pages: any }) => {
                if (data.pages) {
                    this.pages = data.pages;

                }
            });
    }


}