import { Component, OnInit } from '@angular/core';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgVideo from 'lightgallery/plugins/video';
import { environment } from 'src/environments/environment';
import { services } from './../../services/services';


@Component({
  selector: 'app-home-page-eight',
  templateUrl: './home-page-eight.component.html',
  styleUrls: ['./home-page-eight.component.scss']
})
export class HomePageEightComponent implements OnInit {
  list: any[] = [];
  photoUrl: string = environment.serverUrl
  submenus: any;
  orphanMenus: any
  clergy:any[]=[];
  hajj:any;
  umrah:any;
  victim:any;
  alms:any;

  constructor(private services: services) { }

  ngOnInit(): void {
    this.services.getFrontAllPublication().subscribe((data: any) => {
      this.list = data;

    });
    this.getAllFrontMenus();
    this.getAllFrontPage();
  }

  getAllFrontMenus() {
    this.services.getAllFrontMenuRecord().subscribe((data: any) => {
      this.submenus = data.filter(x => x.parentId !== null && x.isActive == true && x.url);
      this.orphanMenus = data.filter(menu => menu.parentId !== null && menu.isActive );
      this.clergy=data.filter(item=>item.id==20);
    })
  }

  getAllFrontPage(){
    this.services.getAllFrontPages().subscribe((data:any) => {
     this.victim=data[4].frontPageRecords[0].code;
     this.hajj=data[5].frontPageRecords[0].code;
     this.umrah=data[6].frontPageRecords[0].code;
     this.alms=data[7].frontPageRecords[0].code;
    })
  }

  isIconOpen = false;

  toggleIcons() {
    this.isIconOpen = !this.isIconOpen;
  }

  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

  //TAB DUYURULAR

  settings = {
    counter: false,
    plugins: [lgVideo]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };

  accordionItems = [
    {
      title: 'Camiler Genelgesi',
      content: 'Camiler öteden beri kırık gönüllerin onarıldığı mekânlar olarak; kapılarını etnik kökeni, siyasi görüşü, mezhebi, meşrebi ve dili ne olursa olsun herkese açmıştır” denilen genelgede namaz vakitleri dışında da vatandaşların dini taleplerine cevap verilebilecek şekilde açık tutulmasının önemli bir ihtiyaç olduğuna dikkat çekildi. 6 Mart itibariyle yürürlüğe giren genelgeye göre ülke genelindeki bütün camiler sabah namazından sonra özel durum ve yerel şartlar dikkate alınarak, öğle namazından en geç bir saat önce açılıp, yatsı namazından sonra kapanmak üzere gün boyunca açık tutulacak.Camilerdeki kadın ve çocuk mekânlarına ilişkin detayların da bulunduğu genelgede şu ifadelere yer verildi',
      open: false
    }
  ];

  vakifYayinlar = [
    {
      title: 'Stockholm Treni (Bir Neslin Göç Hikayeleri)',
      Image: '../../../../assets/cf-imgs/vakifYayinStockholTren.jpeg',
      content: 'Derleyen: Prof. Dr. Adnan Bülent BALOĞLU',
      open: false
    },
    {
      title: 'T-Santral (Hasret Çekenlerin Buluşma Noktası)',
      Image: '../../../../assets/cf-imgs/vakifYayinTSantral.jpeg',
      content: 'Derleyen: Prof. Dr. Adnan Bülent BALOĞLU',
      open: false
    },
    {
      title: 'Mevlid-i Şerif (Türkçe-Osmanlıca-İsveçce)',
      Image: '../../../../assets/cf-imgs/mevlid-iSerif.jpeg',
      content: 'Yazar : Sülayman ÇELEBİ / Çevirmen : Sidney W. HAGE',
      open: false
    }
  ]



  selectedItem = null;

  toggleAccordionItem(list) {
    list.open = !list.open;
    if (this.selectedItem && this.selectedItem !== list) {
      this.selectedItem.open = false;
    }
    this.selectedItem = list;
  }

}
