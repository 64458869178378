<div class="blog-slides">
    <owl-carousel-o [options]="blogSlides">
        <ng-template carouselSlide *ngFor="let new1 of news">
            <div class="single-blog-post mb-30"  [routerLink]="['/haberler/',new1?.code]" style="cursor:pointer">
                <div class="post-image">
                    <a  class="d-block">
                        <img src={{photoUrl+new1?.frontAnnouncementRecords[0]?.fileUrl}} alt="image" class="news-image">
                    </a>
                    <div class="tag">
                        <!-- <h3><a  >Vekaletle Kurban Kesim Organizasyonu Başlamıştır</a></h3> -->
                    </div>
                </div>
                <div class="post-content">
                    <ul class="post-meta">
                        <!-- <li class="post-author">
                            <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                           
                        </li> -->
                        <!-- <li><a routerLink="/">August 30, 2019</a></li> -->
                    </ul>
                    <h3><a>{{new1?.frontAnnouncementRecords[0]?.title}}</a></h3>
                    <!-- <a  class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a> -->
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>