<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/cf-imgs/umree.jpeg" alt="image">
                </a>
            </div>
            <div class="post-content">
                <!-- <a routerLink="/blog-style-1" class="category">Blogger</a> -->
                <h6><a routerLink="/single-blog">2023 Hac Ön Kayıtları Başlamıştır</a></h6>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/cf-imgs/news2.jpeg" alt="image">
                </a>
            </div>
            <div class="post-content">
                <!-- <a routerLink="/blog-style-1" class="category">Blogger</a> -->
                <h6><a routerLink="/single-blog">2023 Yılı Fitre Miktarı 150 Sek</a></h6>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/cf-imgs/umre.jpg" alt="image">
                </a>
            </div>
            <div class="post-content">
                <!-- <a routerLink="/blog-style-1" class="category">Blogger</a> -->
                <h6><a routerLink="/single-blog">Hac Kayıt Başvuruları Başlamıştır!</a></h6>
            </div>

        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/cf-imgs/news4.jpeg" alt="image">
                </a>
            </div>
            <div class="post-content">
                <!-- <a routerLink="/blog-style-1" class="category">Blogger</a> -->
                <h6><a routerLink="/single-blog">2022 YILI VEKALETLE KURBAN KAMPANYASI</a></h6>
            </div>

        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/cf-imgs/news3.jpeg" alt="image">
                </a>
            </div>
            <div class="post-content">
                <!-- <a routerLink="/blog-style-1" class="category">Blogger</a> -->
                <h6><a routerLink="/single-blog">2023 Yılı Ramazan İmsakiyeleri</a></h6>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/cf-imgs/se-kurban.jpeg" alt="image">
                </a>
            </div>
            <div class="post-content">
                <!-- <a routerLink="/blog-style-1" class="category">Blogger</a> -->
                <h6><a routerLink="/single-blog">Vekaletle Kurban Kesim Organizasyonu</a></h6>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>