import { services } from './../services/services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-victim',
  templateUrl: './victim.component.html',
  styleUrls: ['./victim.component.scss']
})
export class VictimComponent implements OnInit {
  pages: any;
  photoUrl: string = environment.serverUrl;

  constructor(private activeRoute: ActivatedRoute, private services: services) { }

  ngOnInit(): void {
    this.activeRoute.data.subscribe(
      (data: { pages: any }) => {
        if (data.pages) {
          this.pages = data.pages;
        }
      });
  }


}
