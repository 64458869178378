
import { Component, OnInit } from '@angular/core';
import lgVideo from 'lightgallery/plugins/video';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent  {


    settings = {
        counter: false,
        plugins: [lgVideo]
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
    };

    accordionItems = [
        {
            title: 'Online Üyelik Başvurusu',
            content: 'ONLİNE BAŞVURU İÇİN TIKLAYINIZ. Başvurunuz gönderildikten sonra faturanız eposta ile ulaştırılacaktır. ',
            open: false
        },
        {
            title: '2023 Yılı Cenaze Fonu Üye Bildirimi',
            content: 'FATURA SON ÖDEME TARİHİ : 28 ŞUBAT 2023 Fonumuzun kuruluş amacı, İsveç’te yaşayan Türkiye Cumhuriyeti vatandaşları ile İsveç vatandaşı statüsündeki Müslümanların, Şartname’de yazılı hususları zamanında yerine getirdikleri takdirde, İsveç’te vefat etmeleri halinde cenazelerinin İslami kurallara göre teçhiz ve tekfininin yapılabilmesi için, resmi işlemlerini tamamlamak, Türkiye veya İsveç’te defnini sağlamak ve bu işlemlerden doğacak masrafları imkânlar ölçüsünde karşılamaktır.',
            open: false
        },
        {
            title: 'Cenaze Fonunun Amacı',
            content: 'İsveç’te yaşayan Türkiye Cumhuriyeti vatandaşları ile İsveç vatandaşı statüsündeki Müslümanların, Şartname ’de yazılı şartları zamanında yerine getirdikleri takdirde, İsveç’te vefat etmeleri halinde cenazelerinin İslami kurallara göre techiz ve tekfininin yapılabilmesi için, resmi işlemlerini tamamlamak, Türkiye veya İsveç’te defnini sağlamak ve bu işlemlerden doğacak masrafları imkânlar ölçüsünde karşılamaktır. Fon, risk payını geçmeyecek şekilde İDV tüzüğündeki esaslar çerçevesinde faaliyet gösteren dernek ve kuruluşlara yardım yapabilir, kendi hizmet ve faaliyet alanını geliştirmek amacıyla yatırım yapabilir. Söz konusu yardım ve yatırım kararlarının alınmasında ve miktarların belirlenmesinde karar mercii Vakıf Yönetim Kurulu’dur.',
            open: false
        },
        {
            title: 'Genel Bilgi',
            content: 'Cenaze Nakli Yardımlaşma Fonu, faaliyet ve hizmetlerini İsveç Diyanet Vakfı’nın bünyesi ve sorumluluğu altında sürdürür. Fon, her türlü hizmetini imkânlar ölçüsünde karşılıklı yardımlaşma esasına dayalı yürütür ve bir şirket statüsüne sahip değildir. Fon üyesi olmak Vakıf üyesi olmak anlamını kesinlikle taşımaz. Detaylı Bilgi ve Üyelik için +46 8 777 91 34 nolu telefondan ulaşabilirsiniz.',
            open: false
        },
        {
            title: 'Fon Üyeliği',
            content: '',
            open: false
        },
        {
            title: 'Fondan Yararlanma Hakkı',
            content: '',
            open: false
        },
        {
            title: 'Aidat Yönetmeliği',
            content: '',
            open: false
        },
        {
            title: 'Fon Üyesinin Vefatı Halinde Yapılacaklar',
            content: '',
            open: false
        },
        {
            title: 'İsveç Dışında Vefat',
            content: '',
            open: false
        },
        {
            title: 'Bilgi Verme Yükümlülüğü',
            content: '',
            open: false
        }
    ];

    selectedItem = null;

    toggleAccordionItem(item) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}