<app-header-style-six></app-header-style-six>


<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>Din Görevlilerimiz</li>
            </ul>
            <h2>Din Görevlilerimiz</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
                     
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let list of list">
                <div class="single-board-item mb-30">
                    <div class="courses-image">
                        <a class="d-block"><img src={{photoUrl+list?.image}} class="img-board"  alt="image"></a>
                    </div>

                    <div class="courses-content">
                        <h3 class="board-title"><a  class="d-inline-block">{{list?.name}} {{list?.surname}}</a></h3>
                        <p class="board-paragraph">{{list?.jobDescription}}</p>
                        <span>{{list?.placeOfDuty}}</span>
                    </div>
                </div>
            </div>
         
    </div>
</div>
<app-header-style-two></app-header-style-two>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->