<app-header-style-six></app-header-style-six>

<div class="page-title-area page-title-style-two item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Anasayfa</a></li>
                <li>Camiler</li>
            </ul>
            <h2>Camiler</h2>
        </div>
    </div>
</div>


<app-homesix-courses></app-homesix-courses>

<!-- <div class="testimonials-wrap-area bg-E7F0FD pt-100">
    <app-happy-students-feedback></app-happy-students-feedback>
</div> -->
<app-header-style-two></app-header-style-two>


<!-- <app-our-mission></app-our-mission> -->

<!-- <div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div>

<div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div>

<app-become-instructor-partner></app-become-instructor-partner> -->