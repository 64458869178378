<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Our News</span> -->
            <!-- <h2>SON HABERLER</h2> -->
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a   class="d-block">
                            <img src="assets/cf-imgs/news4.jpeg" alt="image" class="news-image">
                        </a>
                    </div>
                    <div class="post-content">
                        <!-- <a routerLink="/blog-style-1" class="category">MBA</a> -->
                        <h3><a  >Vekaletle Kurban Kesim Organizasyonu Başlamıştır</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a   class="d-block">
                            <img src="assets/cf-imgs/news2.jpeg" alt="image" class="news-image">
                        </a>
                    </div>
                    <div class="post-content">
                        <!-- <a routerLink="/blog-style-1" class="category">Business</a> -->
                        <h3><a  >2023 Yılı Fitre Miktarı 150 Sek</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a   class="d-block">
                            <img src="assets/cf-imgs/umree.jpeg" alt="image" class="news-image">
                        </a>
                    </div>
                    <div class="post-content">
                        <!-- <a routerLink="/blog-style-1" class="category">Blogger</a> -->
                        <h3><a  >2023 Hac Ön Kayıtları Başlamıştır</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="business-shape8"><img src="assets/img/business-coaching/business-shape6.png" alt="image"></div> -->
</section>