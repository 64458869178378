import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { services } from './../../services/services';

@Injectable()
export class InformationResolver implements Resolve<any> {
    constructor(private services: services) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        const code = route.paramMap.get('code');
        return this.services.getFrontPageByCode(code) as Observable<any>;
    }
}