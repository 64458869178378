import { Component, OnInit } from '@angular/core';
import { services } from './../../services/services';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  photoUrl: string = environment.serverUrl
  news: any;
  constructor(private activeRoute: ActivatedRoute) { }



  ngOnInit() {
    this.activeRoute.data.subscribe(
      (data: { news: any }) => {
        if (data.news) {
          this.news = data.news;
        }
      });
  }


}
