import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderStyleOneComponent } from './components/common/header-style-one/header-style-one.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { HeaderStyleTwoComponent } from './components/common/header-style-two/header-style-two.component';
import { HomePageSixComponent } from './components/pages/home-page-six/home-page-six.component';
import { HeaderStyleThreeComponent } from './components/common/header-style-three/header-style-three.component';
import { HomesixCoursesComponent } from './components/pages/home-page-six/homesix-courses/homesix-courses.component';
import { AboutPageOneComponent } from './components/pages/about-page-one/about-page-one.component';
import { AboutPageTwoComponent } from './components/pages/about-page-two/about-page-two.component';
import { OurValuesComponent } from './components/common/our-values/our-values.component';
import { InstructorsPageTwoComponent } from './components/pages/instructors-page-two/instructors-page-two.component';
import { InstructorsDetailsPageComponent } from './components/pages/instructors-details-page/instructors-details-page.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { HomePageEightComponent } from './components/pages/home-page-eight/home-page-eight.component';
import { HomeeightBlogComponent } from './components/pages/home-page-eight/homeeight-blog/homeeight-blog.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { HeaderStyleFiveComponent } from './components/common/header-style-five/header-style-five.component';
import { HomePageElevenComponent } from './components/pages/home-page-eleven/home-page-eleven.component';
import { HomeelevenMainBannerComponent } from './components/pages/home-page-eleven/homeeleven-main-banner/homeeleven-main-banner.component';
import { HeaderStyleSixComponent } from './components/common/header-style-six/header-style-six.component';
import { HeaderStyleSevenComponent } from './components/common/header-style-seven/header-style-seven.component';
import { HappyStudentsFeedbackComponent } from './components/common/happy-students-feedback/happy-students-feedback.component';
import { PartnerStyleOneComponent } from './components/common/partner-style-one/partner-style-one.component';
import { HttpClientModule } from '@angular/common/http';
import { ActivitiesComponent } from './components/pages/activities/activities.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FuneralFundComponent } from './components/pages/funeral-fund/funeral-fund';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsResolver } from './components/pages/news/news.resolver';
import { VictimComponent } from './components/victim/victim.component';
import { WebPagesComponent } from './components/pages/web-pages/web-pages.component';
import { HomeComponent } from './components/common/home/home.component';
import { WebPagesResolver } from './components/pages/web-pages/web-pages.resolver';
import { VictimResolver } from './components/victim/victim.resolver';
import { HajjResolver } from './components/pages/coming-soon-page/hajj.resolver';
import { UmrahResolver } from './components/pages/courses-details-page/umrah.resolver';
import { InformationApplicationComponent } from './components/common/information-application/information-application.component';
import { InformationResolver } from './components/common/information-application/information.resolver';



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderStyleOneComponent,
    FunfactsComponent,
    HeaderStyleTwoComponent,
    HomePageSixComponent,
    HeaderStyleThreeComponent,
    HomesixCoursesComponent,
    AboutPageOneComponent,
    AboutPageTwoComponent,
    OurValuesComponent,
    InstructorsPageTwoComponent,
    InstructorsDetailsPageComponent,
    LoginPageComponent,
    FaqPageComponent,
    ErrorPageComponent,
    ComingSoonPageComponent,
    ProductsDetailsPageComponent,
    ContactPageComponent,
    CoursesDetailsPageComponent,
    HomePageEightComponent,
    HomeeightBlogComponent,
    FaqComponent,
    HeaderStyleFiveComponent,
    HomePageElevenComponent,
    HomeelevenMainBannerComponent,
    HeaderStyleSixComponent,
    HeaderStyleSevenComponent,
    HappyStudentsFeedbackComponent,
    PartnerStyleOneComponent,
    ActivitiesComponent,
    BlogComponent,
    FuneralFundComponent,
    NewsComponent,
    VictimComponent,
    WebPagesComponent,
    HomeComponent,
    InformationApplicationComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    NgxScrollTopModule,
    LightboxModule,
    FormsModule,
    LightgalleryModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [NewsResolver,WebPagesResolver,VictimResolver,HajjResolver,UmrahResolver,InformationResolver],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
