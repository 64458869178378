<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>Din Hizmetleri Müşaviri</li>
            </ul>
            <h2>Müşavirlik</h2>
        </div>
    </div>
</div>

<div class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="assets/cf-imgs/kerimBirinci.jpg" alt="image">
                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a routerLink="/contact" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                        <ul class="social-link">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="d-block pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Kerim Birinci</h3>
                        <span class="sub-title">Din Hizmetleri Müşaviri</span>
                        <p>1971yılında Düzce’de doğdu.</p>
                        <h3>Çalışma Hayatı </h3>
                        <p>Diyanet İşleri Başkanlığı personeli olarak yurtiçi ve yurtdışında görevlerde bulundu.<br>
                            Halen T.C. Stockholm Büyükelçiliği Sosyal İşler ve Din Hizmetleri Müşavirliği görevini
                            yürütmektedir.

                            Evli ve üç çocuk babasıdır.</p>
                        <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>Eğitim Hayatı</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Uludağ Üniversitesi </span>
                                            İlahiyat Fakültesi
                                        </li>

                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <h3 style="visibility: hidden;">My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Ankara Üniversitesi</span>
                                            Sosyal Bilimler Enstitüsü
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-header-style-two></app-header-style-two>
