<header class="header-area header-color-white">
        <div class="navbar-gap"> </div>
        <header class="header-one sticky-header" [ngClass]="[windowScrolled ? 'sticky-on':'']">
                <div class="header-navigation" [ngClass]="[visible ? '' : 'breakpoint-on']"
                        (window:resize)="detectHeader()">
                        <div class="container-fluid d-flex align-items-center justify-content-between container-1470 ">
                                <span class="header-left">

                                        <a routerLink="/">
                                                <img src="assets/cf-imgs/isvecdvlogo.png" alt="logo" class="logo">
                                        </a>

                                </span>
                                <div class="header-right d-flex align-items-center justify-content-end">
                                        <div class="site-nav-menu" [ngClass]="navMethod ? 'menu-opened':''">
                                                <ul class="primary-menu" style="display: inline-block !important; margin-bottom: 1rem !important;
                                                margin-top:1rem !important;" *ngFor="let item of orphanMenus">
                                                        <li>
                                                                <a *ngIf="item?.frontMenuRecords[0]?.slug === '/'">
                                                                        {{item?.frontMenuRecords[0]?.name }}</a>
                                                                <a href="javascript:void(0)"
                                                                        *ngIf="item?.frontMenuRecords[0]?.slug != '/' && item?.frontMenuRecords[0]?.slug != '/home'"
                                                                        [routerLink]="item?.frontMenuRecords[0]?.slug + '/'+item?.code">{{
                                                                        item?.frontMenuRecords[0]?.name }}</a>
                                                                <a href="javascript:void(0)"
                                                                        *ngIf="item?.frontMenuRecords[0]?.slug === '/home'"
                                                                        [routerLink]="'/'">{{
                                                                        item?.frontMenuRecords[0]?.name }}</a>

                                                                <span class="dd-trigger" *ngIf="hasSubmenus(item)"
                                                                        (click)="trigger(item)">
                                                                        <i class="fal"
                                                                                [ngClass]="item.open ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>

                                                                                
                                                                </span>

                                                                <ul class="submenu" *ngIf="hasSubmenus(item)"
                                                                        [ngClass]="item.open ? 'd-block' : ''">
                                                                        <li *ngFor="let submenu of getSubmenus(item)">
                                                                                <a [href]="submenu?.url == '' ? (submenu?.frontMenuRecords[0]?.slug + '/' + submenu?.code) : submenu?.url"
                                                                              >
                                                                               {{ submenu?.frontMenuRecords[0]?.name }}
                                                                             </a>

                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <!-- Data -->
                                                </ul>
                                                <a href="javascript:void(0)" class="nav-close" (click)="toggleNav()"><i
                                                                class="fal fa-times"></i></a>
                                        </div>
                                        <div class="header-extra d-flex align-items-center">
                                                <div class="offcanvas-widget d-lg-block d-none">

                                                </div>
                                                <div class="nav-toggler" [ngClass]="navMethod ? 'menu-opened':''"
                                                        (click)="toggleNav()">
                                                        <span></span><span></span><span></span>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        </header>


        <div class="top-header top-header-style-four">
                <div class="container">
                        <div class="row align-items-center " style="flex:center">
                                <div class="col-lg-4 col-md-4 prayer-time" style="display: flex;">
                                        <ul class="top-header-contact-info">
                                                <li><a>Namaz Vakitleri</a></li>
                                        </ul>
                                        <a>

                                                <div class="topbar-ordering">
                                                        <select class="form-select prayer-city"
                                                                (change)="onCountrySelected($event)">
                                                                <option *ngFor="let item of country"
                                                                        [value]="item.langcode">
                                                                        {{
                                                                        item.name }}</option>
                                                        </select>
                                                </div>

                                        </a>
                                        <a>

                                                <div class="topbar-ordering">
                                                        <select class="form-select prayer-city"
                                                                (change)="onCitySelected($event)">
                                                                <option *ngFor="let item of cities" [value]="item.id">
                                                                        {{
                                                                        item.name }}</option>
                                                        </select>
                                                </div>

                                        </a>
                                </div>
                                <div class=" time-table col-lg-2 col-md-2">
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Sabah</li>
                                                                <li class="time_text">{{prayerTime?.fajr}}</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Öğlen</li>
                                                                <li class="time_text">{{prayerTime?.dhuhr}}</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">İkindi</li>
                                                                <li class="time_text">{{prayerTime?.asr}}</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Akşam</li>
                                                                <li class="time_text">{{prayerTime?.maghrib}}</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Yatsı</li>
                                                                <li class="time_text">{{prayerTime?.isha}}</li>
                                                        </ul>
                                                </div>
                                        </div>
                                </div>

                        </div>
                </div>
        </div>

</header>

<!-- <div class="search-overlay" [class.active]="classApplied3">
        <div class="d-table">
                <div class="d-table-cell">
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-close" (click)="toggleClass3()">
                                <span class="search-overlay-close-line"></span>
                                <span class="search-overlay-close-line"></span>
                        </div>
                        <div class="search-overlay-form">
                                <form>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                        <button title="submit" type="submit"><i class='bx bx-search-alt'></i></button>
                                </form>
                        </div>
                </div>
        </div>
</div> -->