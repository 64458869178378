import { Component, OnInit } from '@angular/core';
import { services } from './../../services/services';
import { UntypedFormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-funeral-fund',
    templateUrl: './funeral-fund.html',
    styleUrls: ['./funeral-fund.css']
})
export class FuneralFundComponent implements OnInit {
    isLoading = false;
    mainForm: UntypedFormGroup
    spouseForm: UntypedFormGroup
    memberForm: UntypedFormGroup
    showSpouseForm: boolean = false
    showMemberForm: boolean = false


    gender = [
        { id: 1, name: "Kadın" },
        { id: 2, name: "Erkek" }
    ]

    nationality = [
        { id: 1, name: "T.C" },
        { id: 2, name: "Çifte Vatandaş" }
    ]

    constructor(private services: services,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.createMainForm();
        this.createMemberForm();
        this.createSpouseForm();
    }



    createMainForm() {
        this.mainForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            identificationNumber: ['', Validators.required],
            birthPlace: ['', Validators.required],
            birthDay: ['', Validators.required],
            genderId: ['', Validators.required],
            nationality: ['', Validators.required],
            address: this.formBuilder.group({
                street: ['', Validators.required],
                postCode: ['', Validators.required],
                district: ['', Validators.required],
                phoneNumber: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]]
            }),
            familyMembers: this.formBuilder.array([]),
            spouse: this.formBuilder.group({})
        });
    }



    createSpouseForm() {
        this.spouseForm = this.formBuilder.group({
            genderId: ['', Validators.required],
            user: this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                identificationNumber: ['', Validators.required],
                birthPlace: ['', Validators.required],
                birthDay: ['', Validators.required],
                nationality: ['', Validators.required],
            }),
        });
    }

    spouseFormShow() {
        this.showSpouseForm = !this.showSpouseForm;
    }

    showFamilyMemberForm() {
        this.showMemberForm = !this.showMemberForm;
    }

    createMemberForm() {
        this.memberForm = this.formBuilder.group({
            familyMembers: this.formBuilder.array([this.createFamilyMemberForm()])
        });
    }

    createFamilyMemberForm() {
        return this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            identificationNumber: ['', Validators.required],
            birthPlace: ['', Validators.required],
            birthDay: ['', Validators.required],
            genderId: ['', Validators.required]
        });
    }


    get familyMembers() {
        return this.memberForm.get('familyMembers') as FormArray;
    }

    addNewFamilyMember() {
        this.familyMembers.push(this.createFamilyMemberForm());
    }

    saveForm() {      
        this.isLoading = true;

        const formData = {
            mainForm: this.mainForm.value,
        };

        if (this.spouseForm.valid && this.showSpouseForm == true) {
            formData.mainForm.spouse = this.spouseForm.value

        }

        if (this.showMemberForm && this.memberForm.valid) {
            this.memberForm.value.familyMembers.forEach((x) => formData.mainForm.familyMembers.push(x));
        }

        this.services.addFamily(formData.mainForm).subscribe((data: any) => {

            this.isLoading = false;
            if (data.success==true) {
                Swal.fire(
                    {
                        icon: 'success',
                        title: 'Başarılı',
                        text: 'Başvurunuz başarıyla gönderildi!',
                        confirmButtonText: 'Kapat'
                    }
                )
                this.memberForm.reset();
                this.mainForm.reset();
                this.spouseForm.reset();
                
            }
        }, (error: any) => {
            this.isLoading = false;
            if (error && error.errors && error.errors.length > 0) {
                const errorMessage = error.errors[0];

                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Başarısız',
                        text: errorMessage,
                        confirmButtonText: 'Kapat'
                    }
                )
            } else {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Başarısız',
                        text: 'Başvurunuz sırasında bir hata oluştu!',
                        confirmButtonText: 'Kapat'
                    }
                )
            }
        });
    }


    checkInputNumber(event: KeyboardEvent) {
        const allowedKeys = [8, 9, 27, 13, 46];
        if (allowedKeys.includes(event.keyCode)) {
            return;
        }

        if (event.key.match(/^[0-9]$/)) {
            return;
        }

        event.preventDefault();
    }

    checkInputText(event: KeyboardEvent) {
        const allowedKeys = [8, 9, 27, 13, 32, 37, 38, 39, 40, 46];

        if (allowedKeys.includes(event.keyCode)) {
            return;
        }


        if (/^\d$/.test(event.key)) {
            event.preventDefault();
            return;
        }

        const isUpperCase = event.getModifierState('CapsLock') || event.getModifierState('Shift');

        if (!isUpperCase && /^[a-zA-ZçğıiöşüÇĞIİÖŞÜ]$/.test(event.key)) {
            return;
        }

        if (isUpperCase && /^[A-ZÇĞIİÖŞÜ]$/.test(event.key)) {
            return;
        }

        event.preventDefault();
    }

    // checkAge(birthDate: Date): boolean {
    //     const today = new Date();
    //     const age = today.getFullYear() - birthDate.getFullYear();
    //     if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
    //       age--;
    //     }
    //     return age >= 18;
    //   }

}
