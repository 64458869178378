<app-header-style-six></app-header-style-six>


<div class="page-title-area page-title-style-two pages-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>{{pages?.frontPageRecords[0]?.name}}</li>
            </ul>
            <h2>{{pages?.frontPageRecords[0]?.name}}</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="single-board-pages mb-30">
                    <div class="courses-image" *ngIf="pages?.fileUrl">
                        <a class="d-block"><img style="width: auto;
                            margin-bottom: 30px;
                            height: 36vh;" [src]="photoUrl + '/' + pages?.frontPageRecords[0]?.fileUrl"
                                class="img-board" alt="image"></a>
                    </div>

                    <div class="courses-content">
                        <!-- <h3 class="board-title"><a class="d-inline-block">{{pages?.frontPage?.frontPageRecords[0]?.name}}</a></h3> -->
                        <p class="board-paragraph" [innerHTML]="pages?.frontPageRecords[0]?.pageContent"></p>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <app-header-style-two></app-header-style-two>