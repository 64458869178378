<div class="row">
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxs-group'></i>
            </div>
            <!-- <h3><span [countUp]="50">00</span></h3> -->
            <p>Dini Yayınlar</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-book-reader'></i>
            </div>
            <!-- <h3><span [countUp]="1754">00</span></h3> -->
            <p>Makaleler</p>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <a style="color: #fff;" href="https://kurul.diyanet.gov.tr/">
            <div class="single-funfact">
                <div class="icon">
                    <i class='bx bx-question-mark'></i>
                </div>
                <!-- <h3><span [countUp]="8190">00</span></h3> -->
                Dini Sorular
            </div>
        </a>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxs-quote-single-left'></i>
            </div>
            <!-- <h3><span [countUp]="654">00</span></h3> -->
            <p>Duyurular</p>
        </div>
    </div>
</div>