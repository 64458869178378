<div class="container">
    <div class="section-title text-start">
        <span class="sub-title">Vakıf Genel Kurul Üyeleri</span>
        <h2>GENEL KURUL ÜYELERİ </h2>
    </div>
</div>
<div class="container-fluid">
    <div class="testimonials-wrap-slides">
        <owl-carousel-o [options]="testimonialsWrapSlides">
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>Prof. Dr. Ali ERBAŞ</h3>
                            <span>T.C. Diyanet İşleri Başkanı</span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Dr. Selim ARGUN</h3>
                            <span>T.C. Diyanet İşleri Başkan Yardımcısı</span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Erdal ATALAY</h3>
                            <span>T.C. Diyanet İşleri Başkanlığı

                                Dış İlişkiler Genel Müdürü</span>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Pro.Dr. Fazlı ARSLAN</h3>
                            <span>Stockholm Din Hizmetleri Müşaviri</span>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>İsmahan AYRANCI</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Aydan BARAN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Elife EKEN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Didar KANTUR</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Fatih ÇAĞDAŞ</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Mustafa TARHAN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Hanife ÇAKMAK</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Cafer KILIÇ</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Cihat KULLAY</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Oğuzhan BOZKURT</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Mükerrem CÜRE</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Taner AKHAN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Metin TEKİN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Mehmet ÖZER</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Mustafa KESKİN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Serdar BÜKME</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>İlyas ACARALP</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Mehmet Emin CAN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="testimonials-wrap-slides-item">
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/cf-imgs/isvecDiyanetLogo.png" class="rounded-circle" alt="image">

                        <div class="title">
                            <h3>Abdullah ÖZMEN</h3>
                            <span>Genel Kurul Üyesi</span>
                        </div>
                    </div>


                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>