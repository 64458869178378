<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>{{pages.name}}</li>
            </ul>
            <h2>{{pages.name}}</h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="courses-title">
                    
                    </div>
                </div>
            </div>
        </div>
        <div class="container courses-details-desc">
            <div class="row">
                <div class="courses-details-image text-center" *ngIf="pages.fileUrl" >
                    <img style="margin-bottom: 40px;"src={{photoUrl+pages?.fileUrl}} alt="image">
                </div>
                <div class="col-lg-12 col-md-12">

                    <p  [innerHTML]="pages?.pageContent"></p>
                </div>
        
            
            </div>
    </div>
    <app-header-style-two></app-header-style-two>