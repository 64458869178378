
import { Component, OnInit, HostListener } from '@angular/core';
import { ApplicationService } from '../../pages/products-details-page/application.service';
import { prayerTime } from 'src/app/models/prayerTime';
import { services } from '../../services/services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-header-style-six',
    templateUrl: './header-style-six.component.html',
    styleUrls: ['./header-style-six.component.scss']
})
export class HeaderStyleSixComponent implements OnInit {

    prayerTime: prayerTime;
    isSticky: boolean = false;
    selectedCity: any;
    selectedCountry: any;
    country: any;
    cities: any;
    langcode: any;
    submenus: any;
    orphanMenus: any;

    // cities = [
    //     { name: 'Stockholm', latitude: 59.3293, longitude: 18.0686 },
    //     { name: 'Karlskrona', latitude: 56.1612, longitude: 15.5866 },
    //     { name: 'Falun', latitude: 60.6036, longitude: 15.6257 },
    //     { name: 'Visby', latitude: 57.6361, longitude: 18.2960 },
    //     { name: 'Gävle', latitude: 60.6745, longitude: 17.1411 },
    //     { name: 'Halmstad', latitude: 56.6745, longitude: 12.8577 },
    //     { name: 'Östersund', latitude: 63.1767, longitude: 14.6366 },
    //     { name: 'Jönköping', latitude: 57.7826, longitude: 14.1614 },
    //     { name: 'Kalmar', latitude: 56.6631, longitude: 16.3563 },
    //     { name: 'Växjö', latitude: 56.8777, longitude: 14.8099 },
    //     { name: 'Luleå', latitude: 65.5832, longitude: 22.1457 },
    //     { name: 'Malmö', latitude: 55.6044, longitude: 13.0034 },
    //     { name: 'Nyköping', latitude: 58.7528, longitude: 17.0074 },
    //     { name: 'Uppsala', latitude: 59.8588, longitude: 17.6389 },
    //     { name: 'Karlstad', latitude: 59.3795, longitude: 13.5036 },
    //     { name: 'Umeå', latitude: 63.8258, longitude: 20.2630 },
    //     { name: 'Sundsvall', latitude: 62.3898, longitude: 17.3069 },
    //     { name: 'Västerås', latitude: 59.6091, longitude: 16.5448 },
    //     { name: 'Göteborg', latitude: 57.7089, longitude: 11.9746 },
    //     { name: 'Örebro', latitude: 59.2753, longitude: 15.2134 },
    //     { name: 'Linköping', latitude: 58.4108, longitude: 15.6214 }
    // ];




    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private service: services, private route: ActivatedRoute) { }

    ngOnInit() {
        this.detectHeader()
        this.getCountry();
        this.getAllFrontMenus();

    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    getPrayerTime(long, lat) {
        this.service.getPrayerTimes(long, lat).subscribe((resp: any) => {
            this.prayerTime = resp.daily.data[0];
        })
    }

    getCountry() {
        this.service.getFrontAllCountry().subscribe((resp: any) => {
            this.country = resp;
            if (this.country.length > 0) {
                this.selectedCountry = this.country[0].langcode;
                this.getCityByLangCode(this.selectedCountry);
            }
        })
    }


    onCountrySelected(e: any) {
        this.selectedCountry = e.target.value
        this.getCityByLangCode(this.selectedCountry);
    }

    getCityByLangCode(langcode: string) {
        this.service.getFrontCountryByLangcode(langcode).subscribe((data: any) => {
            this.cities = data[0].cities;
            this.getPrayerTime(this.cities[0].longitude, this.cities[0].latitude)
        })
    }

    onCitySelected(x: any) {
        this.selectedCity = x.target.value
        this.service.getFrontCityById(this.selectedCity).subscribe((data: any) => {
            this.getPrayerTime(data.longitude, data.latitude);
        })
    }

    getAllFrontMenus() {
        this.service.getAllFrontMenuRecord().subscribe((data: any) => {
            this.submenus = data.filter(x => x.parentId !== null && x.isActive == true);
            this.orphanMenus = data.filter(menu => {
                return menu.parentId == null && !data.some(submenu => submenu.id === menu.parentId && submenu.isActive == true);
            });
        })
    }

    hasSubmenus(menu: any): boolean {
        return this.submenus.some(submenu => submenu.parentId === menu.id);
    }

    getSubmenus(menu: any): any[] {
        return this.submenus.filter(submenu => submenu.parentId === menu.id);
    }








    windowScrolled: boolean | undefined;

    @HostListener("window:scroll", [])
    onWindowScroll() {
      this.windowScrolled = window.scrollY > 100;
    }
    // Navigation
    navMethod: boolean = false;
    toggleNav() {
      this.navMethod = !this.navMethod;
    }
    // Search
    searchMethod: boolean = false;
    toggleSearch() {
      this.searchMethod = !this.searchMethod;
    }
    // Mobile 
    open: boolean = false;
    trigger(item: { open: boolean; }) {
      item.open = !item.open;
    }
    // Add class on resize and onload window
    visible: boolean = false;
    breakpoint: number = 991;
    public innerWidth: any;
    detectHeader() {
      this.innerWidth = window.innerWidth;
      this.visible = this.innerWidth >= this.breakpoint;
    }
  
    
  

}