<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>İletişim</li>
            </ul>
            <h2>İletişim</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:{{email1}}">{{ email1 }}</a></p>
                    <p><a href="mailto:{{email2}}">{{ email2 }}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Adres</h3>
                    <p><a rel="noopener" href="https://www.google.com/maps/place/{{ address }}" target="_blank">{{ address }}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Telefon</h3>
                    <p><a href="tel:{{ telephone1 }}">{{ telephone1 }}</a></p>
                    <p><a href="tel:{{ telephone2 }}">{{ telephone2 }}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2 style="margin-top: 10px;">İletişim Formu</h2>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel"
                                class="form-control" id="name" placeholder="İsim giriniz">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">İsim zorunludur.</div>
                                <div *ngIf="name.errors.minlength">İsim en az {{
                                    name.errors.minlength.requiredLength }} karakterdir.</div>
                                <div *ngIf="name.errors.maxlength">İsin en fazla 50 karakterdir.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control"
                                id="email" placeholder="E-Posta giriniz">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">E-Posta zorunludur.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control"
                                id="number" placeholder="Telefon">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Telefon zorunludur.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control"
                                id="subject" placeholder="Konu giriniz">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Zorunlu alan.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5"
                                class="form-control" placeholder="Mesajınız..."></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Zorunlu alan.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Gönder</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div> -->

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31698.24959203126!2d17.9833993390406!3d59.262933940088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f773a77e2e46f%3A0x8eb5492dc6044f2e!2zSMOkcmFkc3bDpGdlbiAxNSwgMTQxIDQzIEh1ZGRpbmdlLCDEsHN2ZcOn!5e0!3m2!1str!2str!4v1695974391671!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<app-header-style-two></app-header-style-two>
