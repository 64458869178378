
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { services } from 'src/app/components/services/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-web-pages',
  templateUrl: './web-pages.component.html',
  styleUrls: ['./web-pages.component.scss']
})
export class WebPagesComponent implements OnInit {
  pages: any
  photoUrl: string = environment.serverUrl

  constructor(private activeRoute: ActivatedRoute) { }


  ngOnInit(): void {
    this.activeRoute.data.subscribe(
      (data: { pages: any }) => {
        if (data.pages) {
          this.pages = data.pages;

        }
      });
  }

}
