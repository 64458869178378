import { services } from 'src/app/components/services/services';
import { Component, HostListener, OnInit } from '@angular/core';
import { prayerTime } from 'src/app/models/prayerTime';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  prayerTime: prayerTime;
  isSticky: boolean = false;
  selectedCity: any;
  selectedCountry: any;
  country: any;
  cities: any;
  langcode: any;
  submenus: any;
  orphanMenus: any;
  news: any[]=[];
	photoUrl: string = environment.serverUrl

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollPosition >= 50) {
          this.isSticky = true;
      } else {
          this.isSticky = false;
      }
  }

  constructor(private service :services) { }

  ngOnInit(): void {
    this.detectHeader()
    this.getCountry();
    this.getAllFrontMenus();
  }

  classApplied = false;
  toggleClass() {
      this.classApplied = !this.classApplied;
  }

  classApplied2 = false;
  toggleClass2() {
      this.classApplied2 = !this.classApplied2;
  }

  classApplied3 = false;
  toggleClass3() {
      this.classApplied3 = !this.classApplied3;
  }





  getPrayerTime(long, lat) {
      this.service.getPrayerTimes(long, lat).subscribe((resp: any) => {
          this.prayerTime = resp.daily.data[0];
      })
  }

  getCountry() {
      this.service.getFrontAllCountry().subscribe((resp: any) => {
          this.country = resp;
          if (this.country.length > 0) {
              this.selectedCountry = this.country[0].langcode;
              this.getCityByLangCode(this.selectedCountry);
          }
      })
  }


  onCountrySelected(e: any) {
      this.selectedCountry = e.target.value
      this.getCityByLangCode(this.selectedCountry);
  }

  getCityByLangCode(langcode: string) {
      this.service.getFrontCountryByLangcode(langcode).subscribe((data: any) => {
          this.cities = data[0].cities;
          this.getPrayerTime(this.cities[0].longitude, this.cities[0].latitude)
      })
  }

  onCitySelected(x: any) {
      this.selectedCity = x.target.value
      this.service.getFrontCityById(this.selectedCity).subscribe((data: any) => {
          this.getPrayerTime(data.longitude, data.latitude);
      })
  }

  getAllFrontMenus() {
      this.service.getAllFrontMenuRecord().subscribe((data: any) => {
          this.submenus = data.filter(x => x.parentId !== null && x.isActive == true);
          this.orphanMenus = data.filter(menu => {
              return menu.parentId == null && !data.some(submenu => submenu.id === menu.parentId && submenu.isActive == true);
          });
      })
  }

  hasSubmenus(menu: any): boolean {
      return this.submenus.some(submenu => submenu.parentId === menu.id);
  }

  getSubmenus(menu: any): any[] {
      return this.submenus.filter(submenu => submenu.parentId === menu.id);
  }

  windowScrolled: boolean | undefined;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.windowScrolled = window.scrollY > 100;
  }
  // Navigation
  navMethod: boolean = false;
  toggleNav() {
    this.navMethod = !this.navMethod;
  }
  // Search
  searchMethod: boolean = false;
  toggleSearch() {
    this.searchMethod = !this.searchMethod;
  }
  // Mobile 
  open: boolean = false;
  trigger(item: { open: boolean; }) {
    item.open = !item.open;
  }
  // Add class on resize and onload window
  visible: boolean = false;
  breakpoint: number = 991;
  public innerWidth: any;
  detectHeader() {
    this.innerWidth = window.innerWidth;
    this.visible = this.innerWidth >= this.breakpoint;
  }


	blogSlides: OwlOptions = {
		loop: false,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: false,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 2
			},
			1200: {
				items: 3
			}
		}
	}

}
