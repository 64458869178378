import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageSixComponent } from './components/pages/home-page-six/home-page-six.component';
import { AboutPageOneComponent } from './components/pages/about-page-one/about-page-one.component';
import { AboutPageTwoComponent } from './components/pages/about-page-two/about-page-two.component';
import { InstructorsPageTwoComponent } from './components/pages/instructors-page-two/instructors-page-two.component';
import { InstructorsDetailsPageComponent } from './components/pages/instructors-details-page/instructors-details-page.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { HomePageEightComponent } from './components/pages/home-page-eight/home-page-eight.component';
import { HomePageElevenComponent } from './components/pages/home-page-eleven/home-page-eleven.component';
import { ActivitiesComponent } from './components/pages/activities/activities.component';
import { FuneralFundComponent } from './components/pages/funeral-fund/funeral-fund';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsResolver } from './components/pages/news/news.resolver';
import { VictimComponent } from './components/victim/victim.component';
import { WebPagesComponent } from './components/pages/web-pages/web-pages.component';
import { WebPagesResolver } from './components/pages/web-pages/web-pages.resolver';
import { VictimResolver } from './components/victim/victim.resolver';
import { HajjResolver } from './components/pages/coming-soon-page/hajj.resolver';
import { UmrahResolver } from './components/pages/courses-details-page/umrah.resolver';
import { InformationApplicationComponent } from './components/common/information-application/information-application.component';
import { InformationResolver } from './components/common/information-application/information.resolver';


const routes: Routes = [
    { path: '', component: HomePageEightComponent },
    { path: 'learning-school-online', component: HomePageSixComponent },
    { path: 'home', component: HomePageEightComponent },
    { path: 'gym-coaching', component: HomePageElevenComponent },
    { path: 'cenaze-fonu', component: FuneralFundComponent },
    { path: 'camiler', component: AboutPageTwoComponent },
    { path: 'din-gorevlilerimiz/:slug/:code', component: InstructorsPageTwoComponent },
    {
        path: 'musavirlik/:slug/:code', component: WebPagesComponent,
        resolve: { pages: WebPagesResolver }
    },
    { path: 'login', component: LoginPageComponent },
    { path: 'faqs/:slug/:code', component: FaqPageComponent },
    { path: 'error-404', component: ErrorPageComponent },
    {
        path: 'hac-kayit/:code', component: ComingSoonPageComponent,
        resolve: { pages: HajjResolver }
    },
    {
        path: 'umre-kayit/:code', component: CoursesDetailsPageComponent,
        resolve: { pages: UmrahResolver }
    },
    { path: 'single-products/:slug/:code', component: ProductsDetailsPageComponent },
    { path: 'contact/:slug/:code', component: ContactPageComponent },
    { path: 'hac-basvuru', component: ProductsDetailsPageComponent },
    { path: 'umre-basvuru', component: FaqPageComponent },
    { path: 'hizmetler/:code', component: ActivitiesComponent },
    {
        path: 'kurban/:code', component: VictimComponent,
        resolve: { pages: VictimResolver }
    },
    {
        path: 'zekat/:code', component: VictimComponent,
        resolve: { pages: VictimResolver }
    },
    {
        path: 'hayr-hizmetleri/:slug/:code', component: WebPagesComponent,
        resolve: { pages: WebPagesResolver }
    },
    {
        path: 'din-egitimi/:slug/:code', component: WebPagesComponent,
        resolve: { pages: WebPagesResolver }
    },
    {
        path: 'kulturel-faaliyetler/:slug/:code', component: WebPagesComponent,
        resolve: { pages: WebPagesResolver }
    },

    {
        path: 'haberler/:code', component: NewsComponent,
        resolve: { news: NewsResolver }
    },
    {
        path:'umre-bilgi/:code',component:InformationApplicationComponent
        ,resolve: { pages: InformationResolver }
    }
    ,
    {
        path:'hac-bilgi/:code',component:InformationApplicationComponent
        ,resolve: { pages: InformationResolver }
    },



    // Here add new pages component

    { path: '**', component: ErrorPageComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }