import { Component, OnInit } from '@angular/core';
import { services } from 'src/app/components/services/services';
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit{


  service: any[] = [];
  activity: any[] = [];
  constructor(private services: services) { }

  ngOnInit(): void {
      this.services.getFrontAllActivity().subscribe((data: any) => {
          this.activity = data.filter(list => list.isActive == true);


      });
      this.services.getFrontAllService().subscribe((data: any) => {
          this.service = data.filter(list => list.isActive == true);


      });

  }
  // hizmetler = [
  //   {
  //     title: 'Gençlik ve Spor Hizmetleri',
  //     content: "İsveç ve Norveç'teki Türklerin yaklaşık yarısı çocuk ve gençlerden oluşmaktadır. Gençlerin toplum içerisinde ruhen ve bedenen sağlıklı yetişebilmesinde ve bulunduğu toplumla uyumda gençlerimize çok özel önem vermektedir. Bağlı derneklerimizin tamamına yakın kısmında gençler için özel zaman dilimlerinde yapılan faaliyetler mevcuttur.",
  //     open: false
  //   },
  //   {
  //     title: 'Hayır Hizmetleri',
  //     content: "Din Eğitimi ve İrşâd Hizmetlerinde; İsveç ve Norveç'te sahasında uzman, ilahiyat eğitimi almış din görevlilerince, cemaatimizin de istek ve ihtiyaçları dikkate alınarak, İslam Dini’nin Kur’an'a ve Sünnete uygun olarak, hurafe ve batıl inanışlardan arındırılmış bir şekilde tanıtılması, öğretilmesi amaçlanmaktadır.",
  //     open: false
  //   },
  //   {
  //     title: 'Din Eğitimi ve Din Hizmetleri',
  //     content: "Din Eğitimi ve İrşâd Hizmetlerinde; İsveç ve Norveç'te sahasında uzman, ilahiyat eğitimi almış din görevlilerince, cemaatimizin de istek ve ihtiyaçları dikkate alınarak, İslam Dini’nin Kur’an'a ve Sünnete uygun olarak, hurafe ve batıl inanışlardan arındırılmış bir şekilde tanıtılması, öğretilmesi amaçlanmaktadır.",
  //     open: false
  //   }
  // ]

  // faaliyetler = [
  //   {
  //     title: 'Kültürel Faaliyetler',
  //     content: "İnsanın sağlıklı bir kişiliğe kavuşması için kendi  dinini, kültürünü, tarihini ve geleneklerini bilmesi, geçmişi ve geleceği arasında bağlantı kurarak yaşaması gerekmektedir. Göçmenlerin kültürlerini, dillerini, geleneklerini ve bilhassa dinlerini kısıtlama olmaksızın yaşayabilmeleri halinde, bulundukları yeri öz vatanı gibi benimsemesi daha kolay olacaktır. Farklı kültürlere mensup insanların beraberce ve uyum içerisinde yaşamaları için, karşılıklı kültürlerin tanıtılması ve hoşgörü ile karşılanmasına gayret gösterilmesi gerekir.",
  //     open: false
  //   },
  //   {
  //     title: 'Eğitim ve Entegrasyon',
  //     content: "Daha önce geldiği memleketine dönmek isteyen bir çok kişi, artık bu ülkeyi ikinci vatan olarak seçmiştir. Teşkilatımızca insanlarımızın bu ülkeye uyumlarını kolaylaştırmak için çalışmalar yapılmaktadır.İsveç Diyanet Vakfı, tam bir uyumun, toplumda yaşayan herkesin birbirinin kültürünü, dilini, geleneklerini, özellikle de dinini kabullenmesi, hoşgörmesi ve saygı duyması ile gerçekleşebileceğini savunmaktadır. İsveç Diyanet Vakfı olarak İsveç ve Norveç'te yaşayan insanlarımızın kendi benliklerini kaybetmeden, içinde yaşadıkları toplumla uyum içerisinde yaşamalarını temin etmek ve desteklemek amacıyla, uzman kişi ve kuruluşlarla işbirliği içerisinde gerçekleştirdiğimiz çalışmalardan bazıları aşağıda sıralanmıştır.",
  //     open: false
  //   },
  //   {
  //     title: 'Kadın Kolları',
  //     content: "İsveç Diyanet Vakfı olarak bağlı derneklerimize, kadınlarımızın sosyal yaşamda ve derneklerimizin faaliyetlerinde daha fazla yer almaları, bağlı derneklerin bünyesinde hanımlar kolu çalışma gruplarının kurulması teşvik ve tavsiye edilmiştir. Bu bağlamda merkezimizde ve derneklerimizde uzman kişi ve kuruluşların yardımını da alarak, aşağıdaki faaliyietleri sunulmaktadır. ",
  //     open: false
  //   },
  //   {
  //     title: 'Hac ve Umre',
  //     content: "Diyanet İşleri Başkanlığı'nca düzenlenen Hac ve Umre Organizasyonuna İsveç ve Norveç'ten katılan vatandaşlarımızla ilgili gerekli düzenlemeler, iş ve işlemler konusunda Din Hizmetleri Müşavirliği'ne yardımcı olunmaktadır. Hesap Numaramiz İsveç İçin: 6168-171 546 938 Handelsbanken Hesap Sahibinin Adı Turkish Embassy.",
  //     open: false
  //   }
  // ]



  selectedItem = null;

  toggleAccordionItem(item) {
    item.open = !item.open;
    if (this.selectedItem && this.selectedItem !== item) {
      this.selectedItem.open = false;
    }
    this.selectedItem = item;
  }
}
